@charset "utf-8";

/*
 * 可変フォントサイズ
 */
@mixin scaled-size($property, $rem-size, $rem-size-pc: $rem-size) {
    @include scaled-size-sp($property, $rem-size);
    @include scaled-size-pc($property, $rem-size-pc);
}
@mixin scaled-size-sp($property, $rem-size) {
    #{$property}: calc((1rem + ((1vw - 3.6px) * 5.8)) * #{$rem-size});
    @include media-breakpoint-up(sm) {
        #{$property}: calc(1.3rem * #{$rem-size});
    }
}
@mixin scaled-size-pc($property, $rem-size-pc) {
    @include media-breakpoint-up(md) {
        #{$property}: calc(0.93rem * #{$rem-size-pc});
    }
    @include media-breakpoint-up(lg) {
        #{$property}: calc(1.33rem * #{$rem-size-pc});
    }
    @include media-breakpoint-up(xl) {
        #{$property}: calc(1.53rem * #{$rem-size-pc});
    }
}

/* タイトル文字（大） */
@mixin title-large {
    @include scaled-size('font-size', 1.3, 1.5);
    @include scaled-size('letter-spacing', 0.4);
    color: $app-text-color-blue;
    font-weight: 900;
 }

/* タイトル文字（小） */
@mixin title-small {
    @include scaled-size('font-size', 0.8, 0.6);
    @include scaled-size('letter-spacing', 0.3);
    color: $app-text-color-blue;
    font-weight: 900;
 }

/* 飾り付きボタン */
@mixin decorated-button($text-width: 80%, $bar-color: $white, $bar-width: 2px) {
    margin: 0;
    padding: 0;
    border-radius: 0;
    border-style: none;

    display: flex;
    align-items: center;

    span {
        z-index: 2;
        width: $text-width;
        text-align: center;
    }

    &:after {
        z-index: 2;
        height: $bar-width;
        content: "";
        flex-grow: 1;
        background: $bar-color;
    }
}

/* グラデボタン */
@mixin gradient-button($text-width: 80%) {
    @include decorated-button($text-width, $white, 2px);

    position: relative;
    color: $white;
    background-image: $app-gradient-light;
    cursor: pointer;

    &:before {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        content: "";
        background-image: $app-gradient-dark;
        opacity: 0;
        transition: all 0.3s;
    }

    &:hover::before {
        opacity: 1;
        transition-duration: 1.5s;
    }                    
}

/* READ MORE ボタン */
@mixin readmore-button($text-width: 80%) {
    @include gradient-button($text-width);
    font-style: italic;

    span {
        transform: translateX(-10%) translateY(10%);
    }
}

/* READ MORE 白ボタン */
@mixin readmore-white-button($text-width: 80%) {
    @include decorated-button($text-width, $app-text-color-blue, 1px);
    font-style: italic;

    span {
        transform: translateX(-10%) translateY(10%);
    }
}

/* CSSアニメーション */

@mixin hero-slidein {
    animation: SlideIn 2.6s;
}

@keyframes SlideIn {
    0% {
        opacity: 0;
        transform: translateX(5rem);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

/** テーブルブロック */
@mixin table-block{
    @include media-breakpoint-up(md) {
        @include make-col(10);
    }
    @include scaled-size('padding-top', 1.5, 0.5);
    @include scaled-size('padding-bottom', 4);
    @include scaled-size('padding-left', 2);
    @include scaled-size('padding-right', 2);

    background-image: url(../images/body1.5.png);  
    background-size: 100% 100%;
    background-repeat: no-repeat;     

    .field{
        @include make-row;
        justify-content: center;

        &:not(:first-child):before {
            display: block;
            width: 100%;
            height: 2px;
            @include media-breakpoint-up(md) {
                height: 1px;
            }
            @include scaled-size-pc('margin-left', 3);
            @include scaled-size-pc('margin-right', 3);

            content: "";
            flex-grow: 1;
            background: $app-text-color-green;
        }

        .label{
            @include make-col-ready;
            @include media-breakpoint-up(md) {
                @include make-col(2.5);
            }

            @include scaled-size('margin-top', 1, 1.2);

            color: $app-text-color-green;
            text-align: start;
        }
        .value{
            @include make-col-ready;
            @include media-breakpoint-up(md) {
                @include make-col(7.5);
            }

            @include scaled-size-pc('margin-top', 1.2);

            text-align: start;
        }
    }
}
