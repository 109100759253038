@charset "utf-8";

.navbar{
    width: 100%;
    padding: 0;
    @include media-breakpoint-up(lg) {
        height: 4.4rem;
    }

    background-color: rgba($white, 0.8);
    &.expanded{
        background-image: $app-gradient-light-vert;
        @include media-breakpoint-up(lg) {
            background-color: rgba($white, 0.8);
            background-image: none;
        }
    }

    .navbar-brand {
        padding: 0;
        @include media-breakpoint-up(lg) {
            padding: 1rem;
        }

        img{
            @include img-fluid();
            height: $app-logo-height;
        }
    }

    #navmenu{
        padding-top: 2rem;
        padding-bottom: 2rem;
        @include media-breakpoint-up(lg) {
            height: 100%;
            padding: 0;
            justify-content: flex-end;
        }

        .navbar-nav{
            @include media-breakpoint-up(lg) {
                display: flex;
                height: 100%;
            }
            
            align-items: center;

            a {
                margin-top: 1rem;
      
                font-size: 1.5rem;
                font-weight: 800;
                text-decoration: none;
                @include media-breakpoint-up(lg) {
                    font-size: 0.95rem;
                }

                &.nav-item-link{
                    width: 75%;
                    @include media-breakpoint-up(lg) {
                        width: auto;
                        margin: 0;
                        padding: 0;
                    }

                    color: $white;
                    @include media-breakpoint-up(lg) {
                        color: $black;
                    }
                    
                    @include media-breakpoint-up(lg) {
                        span{
                            position: relative;
    
                            &:hover{
                                color: $app-text-color-green;
    
                                &:before {
                                    position: absolute;
                                    top: 80%;
                                    left: 50%;
                                    content: "・";
                                    transform: translateX(-50%);
                                }
                            }
                        }
                    }

                    &:after{
                        content: "";
                        width: 0.8rem;
                        height: 0.8rem;
                        float: right;
                        border-top: solid 2px $white;
                        border-right: solid 2px $white;
                        transform: translateX(-0.5rem) translateY(0.7rem) rotate(45deg);

                        @include media-breakpoint-up(lg) {
                            transform: none;
                            border-style: none;
                        }
                    }
                    @include media-breakpoint-up(lg) {
                        &.dot:after {
                            margin-left: 1rem;
                            margin-right: 1rem;
                            content: "・";
                            color: $black;
                        }
    
                        &.nodot:after {
                            margin-left: 1rem;
                            margin-right: 1rem;
                            content: "";
                            color: $black;
                        }
                    }
                }
                &.nav-item-contact{
                    width: 75%;
                    height: 4rem;
                    margin-top: 4rem;
                    color: $app-text-color-blue;
                    background-color: $white;

                    @include decorated-button(85%, $black, 1px);

                    @include media-breakpoint-up(lg) {
                        width: 11.5rem;
                        height: 100%;
                        @include gradient-button(70%);
                    }
                }
            }
        }
    }

    /*ハンバーガーアイコン*/
    .navbar-toggler {
        position: relative;
        width: $app-logo-height;
        height: $app-logo-height;
        padding: 0;

        border-style: none;
        border-radius: 0;
        background-image: $app-gradient-light;

        .app-navbar-toggler-icon,
        .app-navbar-toggler-icon:before,
        .app-navbar-toggler-icon:after {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            width: 35px;
            height: 2px;
            margin: auto;
    
            content: '';
            background-color: $white;
            border-radius: 2px;

            transition: all .8s;
        }
        .app-navbar-toggler-icon:before {
            top: $app-logo-height / 2;
        }
        .app-navbar-toggler-icon:after {
            top: $app-logo-height / -2;
        }

        &.expanded{
            background-image: none;

            .app-navbar-toggler-icon:before {
                transform: rotate(-45deg);
                top: 0;
            }
            .app-navbar-toggler-icon {
                background: transparent;
            }
            .app-navbar-toggler-icon:after {
                transform: rotate(45deg);
                top: 0;
            }
        }
    }
}

