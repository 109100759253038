@charset "utf-8";

/* 共通 */

/* タイトル文字（大） */
.title-large{
    @include title-large();
}

/* タイトル文字（小） */
.title-small{
     @include title-small();
 }

/* スマートフォンのみ表示 */
.for-sp {
    display: block;
    @include media-breakpoint-up(md) {
        display: none;
    }
}

/* PCのみ表示 */
.for-pc {
    display: none;
    @include media-breakpoint-up(md) {
        display: block;
    }
}

/* ヘッダ画像 */
.hero {
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;

    .hero-img {
        @include img-fluid;
    }

    .hero-text {
        position: absolute;
        top: 0;
        left: 0;

        color: $app-text-color-blue;
        @include scaled-size('font-size', 1.5);
        font-weight: 900;
        letter-spacing: 0.5rem;
        @include hero-slidein;

        span {
            background-color: rgba($white, 0.8);
        }
    }
}

/**
 * メイン共通
 */
main {
    @include scaled-size('font-size', 0.8, 0.65);
    font-weight: 900;

    /** rowはcontent{数字} */
    @for $i from 1 through 10 {
        #content#{$i}{
            @include make-row;
            justify-content: center;
        }
    }
}