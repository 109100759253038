@charset "utf-8";

@import "../scss/base-style.scss";

/*
 * ページ内mixin
 */

 $page-block-padding: 1.5;

 @mixin base-block {
    @include make-col-ready();
    @include media-breakpoint-up(md) {
        @include make-col(6);
    }
    @include scaled-size('padding-left', $page-block-padding);
    @include scaled-size('padding-right', $page-block-padding);
}

@mixin img-base-block {
    @include base-block;
    @include scaled-size('margin-top', 0, 4);
    @include scaled-size('padding-left', 0);
    @include scaled-size('padding-right', 0);

    img{
        @include img-fluid();
        width: 90%;
        @include scaled-size('margin-top', 3);
    
        @include media-breakpoint-up(md) {
            width: 100%;
        }
    }
}

/*
 * ページレイアウト
 */

.hero{
    .hero-text {
        top: 60%;
        left: 10%;
        @include media-breakpoint-up(md) {
            top: 38%;
            left: 60%;
        }
    }
}

main{

    .page-readmore-btn{
        @include readmore-button();
        @include scaled-size('width', 13);
        @include scaled-size('height', 2.5);
        @include scaled-size('margin-top', 1.5);
        @include scaled-size('font-size', 0.8);
    }
    
    .head-title-block {
        @include base-block;
        color: $app-text-color-blue;
        
        @include media-breakpoint-up(md) {
            & > div {
                margin-left: 15%;
            }
        }

        .heading{
            @include scaled-size('margin-top', 3);
            @include scaled-size('font-size', 0.75);
            @include scaled-size('letter-spacing', 0.2);
            font-style: italic;
        }
    
        .text{
            margin-top: 1rem;
            @include scaled-size('font-size', 1.5);
            @include scaled-size('letter-spacing', 0.4);
            @include scaled-size('line-height', 2, 3.5);
        }
    }

    .head-text-block{
        @include base-block;
        @include scaled-size('margin-top', 0, 4.5);
        @include scaled-size('padding-left', $page-block-padding, 1);
        @include scaled-size('padding-right', $page-block-padding, 1);
        
        & > div {
            @include scaled-size-pc('margin-left', 0);
        }

        .text{
            @include scaled-size('margin-top', 1);
            @include scaled-size('font-size', 0.8);
            @include scaled-size('line-height', 1.5);
        }
    }

    .text-block {
        @include base-block;
        @include scaled-size('margin-top', 0, 8);
        @include scaled-size('padding-left', $page-block-padding, 1);
        @include scaled-size('padding-right', $page-block-padding, 1);
        
        & > div {
            @include scaled-size-pc('margin-left', 0);
            @include scaled-size-pc('margin-right', 1.5);
        }
    
        .title-en{
            @include scaled-size('margin-top', 2);
            @include scaled-size('font-size', 1.7);
            @include scaled-size('letter-spacing', 0.3);
            @include scaled-size('line-height', 1.8);
            color: $app-text-color-blue;
        }
    
        .title-jp{
            @include scaled-size('margin-top', 0.5);
            @include scaled-size('font-size', 1);
        }
    
        .text{
            @include scaled-size('margin-top', 0.5);
            @include scaled-size('font-size', 0.6);
            @include scaled-size('line-height', 1.2);
        }
    }

    .img-left-block {
        @include img-base-block;
    
        img{
            border-radius: 0 100px 0 0;
        }
    }
    
    .img-right-block {
        @include img-base-block;
        @include media-breakpoint-up(md) {
            order: 2;
        }
    
        img{
            float: right;
            border-radius: 100px 0 0 0;
        }
    }
}
