
/* base color */
$app-text-color-blue: #004192;
$app-text-color-green: #8ddbc1;
$app-text-color-emeraldgreen: #008667;
$app-text-color-footer: #0A2132;
$app-text-color-footer-gray: #768897;
$app-text-color-lightblue: #e5ecf4;

$app-gradient-light: linear-gradient(90deg, #8DDBC1 0%, #016DAA 100%);
$app-gradient-light-vert: linear-gradient(180deg, #8DDBC1 0%, #016DAA 100%);
$app-gradient-dark: linear-gradient(90deg, #7ab5b3 0%, #004193 100%);

/* header */
$app-logo-height: 42px;

/* contents */
$app-block-left-margin: 2rem;
