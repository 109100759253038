@charset "utf-8";

.recruit {
    position: relative;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    @include scaled-size('margin-top', 4.5);

    .recruit-img {
        @include img-fluid;
        width: 100%;
    }

    .more-btn-wrap{
        position: absolute;
        @include scaled-size('bottom', 2, 3.5);
        left: 50%;
        transform: translateX(-50%);

        .more-btn {
            @include scaled-size('width', 8, 10);
            @include scaled-size('height', 1.8, 2.3);
            @include scaled-size('margin-top', 1.5);
            @include scaled-size('font-size', 0.6, 0.8);
            @include readmore-white-button();
            background-color: $white;
        }
    }
}

