@charset "utf-8";

footer{
    @include scaled-size('margin-top', 2.5);

    color: $app-text-color-footer;
    font-weight: 900;
    
    .content1{
        @include make-row();
        background-image: linear-gradient(90deg, #e8f7f3 0%, #cce2ee 100%);

        @include media-breakpoint-up(md) {
            @include scaled-size('height', 16);
        }

        .block1{
            @include make-col-ready();
            @include media-breakpoint-up(md) {
                @include make-col(5.6);
            }
            @include scaled-size('padding-top', 2, 1.5);
            @include scaled-size('padding-left', 2, 4);
            @include scaled-size('padding-right', 2, 0);
    
            @include scaled-size('font-size', 0.9, 0.6);

            .footer-brand-wrap{
                @include scaled-size('margin-left', -0.5);

                .footer-brand{
                    img{
                        @include img-fluid();
                        height: $app-logo-height;
                    }
                }
            }

            .com{
                @include scaled-size('margin-top', 1);
                @include scaled-size('margin-bottom', 0.2);
            }
            .addr1{
                float: left;
                &::after{
                    content: "　";
                }
            }
            .addr2{
                float: left;
            }
            .addr3{
                float: left;
                clear: both;
                &::after{
                    content: "　";
                }
            }
            .addr4{
                float: left;
            }
            .tel{
                float: left;
                clear: both;
                @include scaled-size('margin-top', 0.5);
            }
        }

        .block2{
            @include make-col-ready();
            @include scaled-size('margin-top', 1.5, 1.5);
            @include scaled-size('padding-left', 2, 0);
            @include scaled-size('padding-right', 2, 0);

            @include media-breakpoint-up(md) {
                @include make-col(1.7);
            }

            &:before {
                display: block;
                width: 100%;
                height: 2px;
                content: "";
                flex-grow: 1;
                background: $app-text-color-blue;

                @include media-breakpoint-up(md) {
                    content: none;
                }
            }
        }

        .block3{
            @include make-col-ready();
            @include media-breakpoint-up(md) {
                @include make-col(2.3);
            }
            @include scaled-size('margin-top', 0, 1.5);
            @include scaled-size('padding-left', 2, 0);
            @include scaled-size('padding-right', 2, 0);
        }

        .block4{
            @include make-col-ready();
            @include media-breakpoint-up(md) {
                @include make-col(2.4);
            }
            @include scaled-size('margin-top', 0, 1.5);
            @include scaled-size('padding-left', 2, 0);
            @include scaled-size('padding-right', 2, 4);

            @include scaled-size('margin-bottom', 1.5);
            &::after {
                display: block;
                width: 100%;
                height: 2px;
                content: "";
                flex-grow: 1;
                background: $app-text-color-blue;
    
                @include media-breakpoint-up(md) {
                    content: none;
                }
            }
        }

        .block2, .block3, .block4{
            @include scaled-size('font-size', 0.9, 0.6);

            .heading, .contact{
                a {
                    display: block;
                    @include scaled-size('padding-top', 1, 0.2);
                    @include scaled-size('padding-bottom', 1, 0.2);

                    color: $app-text-color-footer;
                    font-weight: 900;
                    text-decoration: none;
                    cursor: pointer;

                    &:after{
                        content: "";
                        width: 0.8rem;
                        height: 0.8rem;
                        float: right;

                        border-bottom: solid 2px $app-text-color-footer-gray;
                        border-right: solid 2px $app-text-color-footer-gray;
                        transform: translateX(-0.5rem) translateY(0.2rem) rotate(45deg);

                        @include media-breakpoint-up(md) {
                            content: none;
                        }
                    }
                }

                &.expanded {
                    a {
                        &:after{
                            transform: translateX(-0.5rem) translateY(0.5rem) rotate(225deg);
                        }
                    }
                }
            }

            .menu-detail {
                @include media-breakpoint-up(md) {
                    display: block;
                }

                & > div {
                    display: flex;
                    flex-direction: column;

                    a{
                        display: block;
                        @include scaled-size('padding-top', 0.7, 0.2);
                        @include scaled-size('padding-bottom', 0.7, 0.2);

                        color: $app-text-color-footer-gray;
                        font-weight: 900;
                        text-decoration: none;
                    }
                }
            }
        }

        .block3, .block4{
            .heading, .contact{
                &:before {
                    display: block;
                    width: 100%;
                    height: 1px;
                    content: "";
                    flex-grow: 1;
                    background: $app-text-color-footer;

                    @include media-breakpoint-up(md) {
                        content: none;
                    }
                }
            }
        }

        @include media-breakpoint-up(md) {
            .block4{
                .contact{
                    @include scaled-size('margin-top', 0, 3);
                }
            }
        }
    }

    .content2{
        @include make-row();
        background-image: linear-gradient(90deg, #e8f7f3 0%, #cce2ee 100%);
        @include scaled-size('font-size', 0.8, 0.5);

        @include media-breakpoint-up(md) {
            &:before {
                display: block;
                width: 100%;
                height: 2px;
                @include scaled-size('margin-left', 0, 4);
                @include scaled-size('margin-right', 0, 4);

                content: "";
                flex-grow: 1;
                background: $app-text-color-blue;
            }
        }

        .block1{
            @include make-col-ready();

            @include scaled-size('padding-top', 0, 0.5);
            @include scaled-size('padding-bottom', 1.5, 2);
            @include scaled-size('padding-left', 2, 4);
            @include scaled-size('padding-right', 2, 0);

            @include media-breakpoint-up(md) {
                @include make-col(6);
            }

            .sub-row{
                @include media-breakpoint-up(md) {
                    display: flex;
                }
                
                & > div{
                    @include scaled-size('margin-top', 0.8, 0);
                    @include scaled-size('margin-right', 0, 0.8);
        
                    a {
                        color: $app-text-color-footer;
                        font-weight: 900;
                        text-decoration: none;
                    }
                }
            }
        }

        .block2{
            @include make-col-ready();

            @include scaled-size('padding-top', 1, 0.5);
            @include scaled-size('padding-bottom', 1, 2);
            @include scaled-size('padding-left', 2, 0);
            @include scaled-size('padding-right', 2, 4);
            background-image: $app-gradient-light;

            color: $white;
            font-weight: 900;

            @include media-breakpoint-up(md) {
                @include make-col(6);
                display: flex;
                justify-content: flex-end;
                color: $app-text-color-footer;
                background-image: none;
            }
    
            @include media-breakpoint-up(md) {
                & > div{
                    @include scaled-size('margin-left', 0.8);
                }
            }
        }
    }
}
